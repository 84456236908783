<template>
  <Layout>
    <PageHeader :items="items" :title="title" />
    <div class="card" style="margin-top: -30px">
      <div class="card-body">
        <div class="inner mb-2 row">
          <div
              class="col-md-2"
              v-if="
             login_type == 2 ||
                this.$storageData.profile.empTypeID === 2 ||
                this.$storageData.profile.empTypeID === 11
            "
          >
            <label> Country </label>
            <multiselect
                v-model="country"
                :options="countryArr"
                track-by="country"
                label="country"
                @input="getRestaurantCities()"
                :show-labels="false"
            ></multiselect>
          </div>
          <div
              class="col-md-2"
              v-if="
              login_type == 2 ||
                this.$storageData.profile.empTypeID === 2 ||
                this.$storageData.profile.empTypeID === 11
            "
          >
            <label> City </label>
            <multiselect
                v-model="city"
                :options="cityArr"
                track-by="city"
                label="city"
                @input="readRestaurantBranchData()"
                :show-labels="false"
            ></multiselect>
          </div>
          <div
              class="col-md-2"
              v-if="
             login_type == 2 ||
                this.$storageData.profile.empTypeID === 11
            "
          >
            <label> Restaurant Brand</label>
            <multiselect
                v-model="brandData"
                :options="brandArr"
                track-by="restID"
                label="name"
                @input="readRestaurantBranchData()"
                :show-labels="false"
            ></multiselect>
          </div>
          <div
              class="col-md-2"
              v-if="
              login_type == 2 ||
                this.$storageData.profile.empTypeID === 2 ||
                this.$storageData.profile.empTypeID === 11
            "
          >
            <label> Restaurant Branch </label>
            <multiselect
                v-model="branchID"
                :options="branches"
                track-by="restBranchID"
                label="branchName"
                @input="onchangeBranch()"
            ></multiselect>
          </div>
          <div class="col-md-4 mb-3" v-if="login_type == 1">
            <label class="form-label" for="formrow-endTime-input">
              Corporate Branch
            </label>
            <multiselect
                v-model="corpBranchID"
                :options="corporates"
                track-by="corpBranchID"
                label="corpBranchName"
            ></multiselect>
          </div>
          <!-- Apply filter -->
          <div class="col-md-2" style="width: auto; margin-top: 22px">
            <button class="btn btn-themeOrange" v-on:click="applyFilter()">
              Apply Filter
            </button>
          </div>
          <!-- Apply filter END-->
          <!-- Clear filter -->
          <div class="col-md-2" style="width: 16%; margin-top: 22px">
            <button class="btn btn-themeBrown" v-on:click="clearFilter()">
              Clear Filter
            </button>
          </div>
          <!-- Clear filter END-->
          <!--END Cities List-->
        </div>
      </div>
    </div>
    <div class="row" style="margin-top: 20px">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mt-4">
              <div
                  class="col-sm-12 col-md-4"
                  style="margin-bottom: 15px;"
                  v-if="login_type == 1"
              >
                <div role="group" class="btn-group">
                  <button
                      type="button"
                      class="btn btn-themeBrown"
                      @click="downloadSample()"
                  >
                    Excel
                  </button>
                </div>
              </div>
              <div class="col-sm-12 col-md-4">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show &nbsp;&nbsp;
                    <b-form-select
                        style="margin-left: 5px; margin-right: 5px"
                        v-model="perPage"
                        size="sm"
                        :options="pageOptions"
                    ></b-form-select
                    >&nbsp;&nbsp;&nbsp; entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-4 row">
                <div
                    id="tickets-table_filter"
                    class="dataTables_filter text-md-end"
                >
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                        v-model="filter"
                        type="search"
                        placeholder="Search..."
                        class="form-control form-control-sm ms-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                  striped
                  hover
                  outlined
                  bordered
                  :items="tableData"
                  :fields="fields"
                  responsive
                  :per-page="perPage"
                  :current-page="currentPage"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  @filtered="onFiltered"
              >
                <!-- <template #cell(Action)>
                  <i class="uil uil-edit-alt" title="Edit" style="font-size: 19px;"></i>
                  <i class="mdi mdi-delete" title="Delete" style="font-size: 19px;"></i>
                </template> -->

                <template v-slot:cell(Action)="data">
                  <router-link
                      v-if="login_type == 2"
                      :to="{
                      name: 'configureMealPlanForm',
                      params: { id: data.item.corpMealPlanID },
                    }"
                  >
                    <button class="btn btn-themeYellow" style="color: #5a5a5a">
                      Configure Meal Plan
                    </button>
                  </router-link>
                  <router-link
                      v-if="login_type == 1 && editRight == 1"
                      :to="{
                      name: 'corporateMealPlanForm',
                      params: { id: data.item.corpMealPlanID, type: 'edit' },
                    }"
                  >
                    <button class="btn btn-themeYellow" style="color: #5a5a5a">
                      Edit
                    </button>
                  </router-link>
                  <router-link
                      v-if="
                      login_type == 1 ||
                        (login_type == 3 && editRight == 1)
                    "
                      :to="{
                      name: 'corporateMealPlanForm',
                      params: { id: data.item.corpMealPlanID, type: 'editMin' },
                    }"
                  >
                    <button
                        class="btn btn-themeYellow"
                        style="color: #5a5a5a; margin-left: 3px"
                    >
                      Edit Min Guarantee
                    </button>
                  </router-link>
                  <i
                      class="mdi mdi-delete"
                      title="Delete"
                      style="font-size: 19px"
                      v-if="deleteRight == 1"
                  ></i>
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div
                    class="dataTables_paginate paging_simple_numbers float-end"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                        v-model="currentPage"
                        :total-rows="rows"
                        :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Multiselect from "vue-multiselect";

export default {
  page: {
    title: "Meal Plan Table",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {Layout, PageHeader, Multiselect},
  data() {
    return {
      tableData: [],
      excelData: [],
      corporates: [],
      corpBranchID: "",
      brandArr: [],
      branchData: "",
      brandData: "",
      title: "Meal Plan Table",
      items: [
        {
          text: "View",
        },
        {
          text: "Meal Plan Table",
          active: true,
        },
      ],
      cityArr: [],
      city: "",
      countryArr: [],
      country: "",
      branches: [],
      branchID: "",
      restBranchID: 0,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100, 500, 1000, 2000, "All"],
      filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      fields: [
        {
          key:
              localStorage.getItem('loginType') == 1 ||
        localStorage.getItem('loginType') == 3
                  ? "restaurant"
                  : "corporate",
          sortable: true,
        },
        {
          key: "applicableTo",
          sortable: true,
        },
        {
          key: "startDate",
          sortable: true,
        },
        {
          key: "endDate",
          sortable: true,
        },
        {
          key: "Action",
          sortable: true,
        },
      ],
      path: "",
      editRight: "",
      deleteRight: "",
      login_type:"",
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
    this.login_type = localStorage.getItem('loginType');
    this.login_type == 1
        ? this.getCorporateList()
        : this.getRestaurantCountry();
    this.checkUserRights();
  },
  methods: {
    checkUserRights() {
      this.path = this.$route.path;
      this.axios
          .post(this.$loggedRole + "/checkUserRights", {
            empID: this.$storageData.profile.pid,
            empTypeID: this.$storageData.profile.empTypeID,
            path: this.path,
          })
          .then((result) => {
            this.editRight = result.data.data[0].isEdit;
            this.deleteRight = result.data.data[0].isDelete;
          });
    },

    //excel code
    downloadSample() {
      this.axios
          .post(this.$loggedRole + "/exportExcel",
              {
                exportData: this.excelData,
                page: "Meal Plan Table",
              },
              {
                responseType: "blob",
              }
          )
          .then((response) => {
            const url = URL.createObjectURL(
                new Blob([response.data], {
                  type: "application/vnd.ms-excel",
                })
            );
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "Meal-Plan Table.xlsx");
            document.body.appendChild(link);
            link.click();
          });
    },
    //excel code end

    /**
     * Search the table data with search input
     */
    getRestaurantBrandsByCompanyID() {
      this.axios
          .post(this.$loggedRole + "/getRestaurantBrandsByCompanyID", {
            companyID: this.$storageData.profile.companyID,
            // restID: this.$storageData.profile.restID ? this.$storageData.profile.restID : 0,
            restID: this.login_type == 2 && this.$storageData.profile.empTypeID != 11 ? this.$storageData.profile.restID : 0,
          })
          .then((response) => {
            this.brandArr = response.data.data;
            this.brandData = this.brandArr ? this.brandArr[0] : "";
            this.readRestaurantBranchData();
          });
    },
    getRestaurantCountry() {
      this.axios
          .post(this.$loggedRole + "/getRestaurantCountry", {
            restID: this.$storageData.profile.restID,
            company_id:this.$storageData.profile.companyID
          })
          .then((response) => {
            this.countryArr = response.data.data;
            this.country = this.countryArr ? this.countryArr[0] : "";
            this.getRestaurantCities();
          });
    },
    getRestaurantCities() {
      this.axios
          .post(this.$loggedRole + "/getRestaurantCities", {
            restID:
                this.login_type == 1
                    ? this.restaurantID.restID
                    : this.brandData
                        ? this.brandData.restID
                        : this.$storageData.profile.restID,
            country: this.country ? this.country.country : "",
            company_id:this.$storageData.profile.companyID
          })
          .then((response) => {
            this.cityArr = response.data.data;
            this.city = this.cityArr ? this.cityArr[0] : "";
            if (
                this.login_type == 2 ||
                this.$storageData.profile.empTypeID == 11
            ) {
              this.getRestaurantBrandsByCompanyID();
            } else {
              this.readRestaurantBranchData();
            }
          });
    },
    readRestaurantBranchData() {
      this.axios
          .post(this.$loggedRole + "/getRestaurantBranchByRestID", {
            restID:
                this.brandData != ""
                    ? this.brandData.restID
                    : this.$storageData.profile.restID,
            restBranchID:
                this.login_type == 2 ||
                this.$storageData.profile.empTypeID == 2 ||
                this.$storageData.profile.empTypeID == 11
                    ? 0
                    : this.$storageData.profile.restBranchID,
            empTypeID: this.$storageData.profile.empTypeID,
            city: this.city ? this.city.city : "",
          })
          .then((response) => {
            this.branches = response.data.data;
            this.branchID = this.branches ? this.branches[0] : "";
            this.restBranchID = this.branchID ? this.branchID.restBranchID : "";
            // this.readMealPlanData();
          });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    readMealPlanData() {
      this.axios
          .post(this.$loggedRole + "/viewAllCorporateMealPlan", {
            corpBranchID: this.corpBranchID.corpBranchID
                ? this.corpBranchID.corpBranchID
                : this.$storageData.profile.corpBranchID,
            loginTypeID: this.login_type,
            restBranchID:
                this.login_type == 2 ||
                this.$storageData.profile.empTypeID == 2 ||
                this.$storageData.profile.empTypeID == 11
                    ? this.restBranchID
                    : this.$storageData.profile.restBranchID,
          })
          .then((response) => {
            //Then injecting the result to datatable parameters.
            //  this.excelFields = {};
            this.tableData = response.data.data;
            this.excelData = response.data.excelData;
            //  this.fields.forEach(element => {
            //               // console.log(element.key);
            //               var key = element.key;
            //               // var tempObj = {key: key};
            //               this.excelFields['' + key + ''] = key;
            //               // console.log(tempObj);
            //               // tempExcelFields = {tempExcelFields, tempObj};
            //           });
            //console.log((response));
          });
    },
    getCorporateList() {
      this.axios.get(this.$loggedRole + "/getMealPlanCorporateBranchList").then((result) => {
        this.corporates = result.data.data;
        // this.readMealPlanData();
      });
    },

    onchangeBranch() {
      this.restBranchID = this.branchID ? this.branchID.restBranchID : "";
    },

    clearFilter() {
      this.country = "";
      this.city = "";
      this.branchID = "";
      this.brandData = "";
      this.corpBranchID = "";
      this.corporates = [];
      this.dateFilter = [];
      this.tableData = [];
      this.excelData = [];

      this.getCorporateList();
      this.getPrincipleCountry();
      // this.readMealPlanData();
    },

    applyFilter() {
      this.principleBranchID = this.branchID.principleBranchID;
      this.readMealPlanData();
      // this.readCorporateBranches(this.principleBranchID);
    },
  },
  middleware: "authentication",
};
</script>


<style scoped>
.my-class {
  max-width: 10px !important;
}
</style>
